import { makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles({
  container: {
    background: COLORS.BASE,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  label: {
    color: COLORS.GREY_INPUT,
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    fontSize: '1.2rem',
    marginBottom: '1rem',
  },
  link: {
    color: COLORS.ACCENT,
    textDecorationLine: 'underline',
    fontFamily: FONTS.POPPINS_BOLD,
    fontSize: '0.7rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  input: {
    background: COLORS.GREY_INPUT,
    borderRadius: 5,
    fontFamily: FONTS.POPPINS_REGULAR,
    fontSize: '0.8rem',
    paddingLeft: 10,
    height: 45,
    width: '90%',
    color: COLORS.GREY_INPUT_TEXT,
  },
  inputInner: {
    padding: '6px 0 6px',
    lineHeight: '23px',
  },
  button: {
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    color: COLORS.BASE,
    textTransform: 'capitalize',
    marginTop: 10,
    width: 150,
  },
})

export { useStyles }
