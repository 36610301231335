import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core/'

import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg'
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg'

import { useStyles } from './styles'

interface HeaderProps {
  title?: string
  titleFontFamily?: string
  titleColor?: string
  showRightButton?: boolean
}

const Header: React.FC<HeaderProps> = ({ title, titleFontFamily, titleColor, showRightButton }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <div className={classes.appBar} />
      <AppBar className={classes.appBar} elevation={0} position="absolute">
        <Toolbar className={classes.toolbar}>
          <IconButton color="primary" onClick={history.goBack} className={classes.backButton}>
            <ArrowBackIcon width={30} height={30} />
          </IconButton>
          <Typography
            variant="h6"
            className={classes.title}
            style={{ fontFamily: titleFontFamily, color: titleColor }}
          >
            {title}
          </Typography>
          <IconButton color="primary">
            {showRightButton ?? <QuestionIcon width={30} height={30} />}
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  )
}

export { Header }
