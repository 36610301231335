import React from 'react'
import { useHistory } from 'react-router'
import { Container, Button, Grid } from '@material-ui/core'

import { ReactComponent as Logo } from 'assets/images/greeneat-logo.svg'

import { useStyles } from './styles'

const Home: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Container className={classes.container} maxWidth={false}>
      <Logo width={200} height={200} />
      <Grid container spacing={1} direction="column" justify="center" alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={() => history.push('reader')}
          >
            Escanear código QR
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={() => history.push('code')}
          >
            Ingresar código
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export { Home }
