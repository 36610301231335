import React, { useState, useCallback } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { useApiCall } from 'hooks'

import { Detail } from './Detail'
import { EnterCode } from './EnterCode'
import { QrReader } from './QrReader'
import { User, GetUserResponse } from './types'
import { getUser } from './api'

const Main: React.FC = () => {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [getUserApi] = useApiCall<string, GetUserResponse>(getUser)

  const getUserByQrCode = useCallback(
    async (qrCode: string) => {
      const { user: apiUser } = await getUserApi(qrCode)
      setUser(apiUser)
    },
    [getUserApi],
  )

  return (
    <Switch>
      <Route path="/code" render={() => <EnterCode getUserByQrCode={getUserByQrCode} />} />
      <Route path="/reader" render={() => <QrReader getUserByQrCode={getUserByQrCode} />} />
      <Route
        path="/detail/:code"
        render={() => <Detail getUserByQrCode={getUserByQrCode} user={user} />}
      />
      <Redirect to="/home" />
    </Switch>
  )
}

export { Main }
