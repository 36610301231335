import { makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles({
  appBar: {
    backgroundColor: COLORS.GREY_INPUT,
    height: 60,
  },
  toolbar: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  backButton: {
    padding: 0,
  },
  title: {
    color: COLORS.BASE,
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    fontSize: '1rem',
  },
})

export { useStyles }
