import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'styles'

const useStyles = makeStyles({
  container: {
    background: COLORS.BASE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
})

export { useStyles }
