import React, { useState, useEffect } from 'react'
import QrReaderComponent from 'react-qr-reader'
import { useHistory } from 'react-router'

import { Header, snackbar } from 'components'
import { ReactComponent as TopLeftCorner } from 'assets/icons/top-left-corner.svg'
import { ReactComponent as TopRightCorner } from 'assets/icons/top-right-corner.svg'
import { ReactComponent as BottomLeftCorner } from 'assets/icons/bottom-left-corner.svg'
import { ReactComponent as BottomRightCorner } from 'assets/icons/bottom-right-corner.svg'
import { ReactComponent as CheckLogo } from 'assets/icons/check.svg'
import { ERRORS } from 'consts'

import { ErrorModal } from './components'
import { useStyles } from './styles'

interface QrReaderProps {
  getUserByQrCode: (code: string) => Promise<void>
}

const QrReader: React.FC<QrReaderProps> = ({ getUserByQrCode }) => {
  const classes = useStyles()
  const [showCheck, setShowCheck] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [qrData, setQrData] = useState()
  const history = useHistory()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!qrData) setOpenErrorModal(true)
    }, 45000)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getUser = async () => {
      try {
        await getUserByQrCode(qrData)
        history.push(`/detail/${qrData}`)
      } catch (error) {
        if (error.type === 'ResourceNotFound') {
          snackbar.show(ERRORS.QR_NOT_FOUND_MESSAGE)
          history.push('/home')
        } else setOpenErrorModal(true)
      }
    }

    if (qrData) {
      setShowCheck(true)
      getUser()
    }
  }, [qrData, history, getUserByQrCode])

  return (
    <div className={classes.container}>
      <Header title="Escanear código QR" />
      <div className={classes.qrView}>
        <div className={classes.overlay}>
          <TopLeftCorner width={30} height={30} className={classes.topLeftCorner} />
          <TopRightCorner width={30} height={30} className={classes.topRightCorner} />
          <BottomLeftCorner width={30} height={30} className={classes.bottomLeftCorner} />
          <BottomRightCorner width={30} height={30} className={classes.bottomRightCorner} />
          {showCheck && <CheckLogo width="100%" height="100%" className={classes.checkLogo} />}
        </div>
        <QrReaderComponent
          className={classes.qrReader}
          delay={300}
          showViewFinder={false}
          onError={() => setOpenErrorModal(true)}
          onScan={data => {
            if (data) setQrData(data)
          }}
        />
      </div>
      <ErrorModal open={openErrorModal} />
    </div>
  )
}

export { QrReader }
