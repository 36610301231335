import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import { COLORS } from 'styles'
import { setAuthHeader } from 'utils'
import { useApiCall, useLogOut, useLogIn } from 'hooks'
import { Admin, TokenLoginResponse } from 'types'
import { tokenLogin } from 'api'
import { AdminContext } from 'contexts'
import { SnackbarContainer, snackbar, ProtectedRoutes } from 'components'

import { Login } from './Login'
import { Home } from './Home'
import { Splash } from './Splash'
import { Main } from './Main'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.BASE,
      contrastText: COLORS.WHITE,
    },
    secondary: {
      main: COLORS.ACCENT,
    },
  },
})

const App: React.FC = () => {
  const [isAppLoading, setIsAppLoading] = useState(true)

  const [admin, setAdmin] = useState<Admin | undefined>(() => {
    const localAdmin = localStorage.getItem('admin')
    if (!localAdmin) return undefined
    return JSON.parse(localAdmin)
  })

  const [tokenLoginApi] = useApiCall<void, TokenLoginResponse>(tokenLogin)

  const [logIn] = useLogIn()
  const [logOut] = useLogOut()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      setIsAppLoading(false)
      return
    }

    const updateAdmin = async () => {
      try {
        setAuthHeader(token)
        const { user: updatedAdmin } = await tokenLoginApi()
        logIn(token, updatedAdmin)
      } catch (_) {
        logOut()
      } finally {
        setIsAppLoading(false)
      }
    }
    updateAdmin()
  }, [logIn, logOut, tokenLoginApi])

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {isAppLoading ? (
        <Splash />
      ) : (
        <AdminContext.Provider value={{ admin, setAdmin }}>
          <Router>
            <Switch>
              <Route path="/login" component={Login} />
              <ProtectedRoutes>
                <Switch>
                  <Route path="/home" component={Home} />
                  <Main />
                </Switch>
              </ProtectedRoutes>
            </Switch>
          </Router>
          <SnackbarContainer
            ref={ref => {
              snackbar.containerInstance = ref
            }}
          />
        </AdminContext.Provider>
      )}
    </MuiThemeProvider>
  )
}

export { App }
