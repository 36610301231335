import { makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles({
  container: {
    background: COLORS.GREY_INPUT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  button: {
    fontFamily: FONTS.POPPINS_BOLD,
    color: COLORS.ACCENT,
    textTransform: 'inherit',
    marginTop: 10,
    width: 250,
    height: 60,
    fontSize: '1rem',
    borderRadius: 10,
  },
})

export { useStyles }
