import React from 'react'
import { Container } from '@material-ui/core'

import { ReactComponent as Logo } from 'assets/images/greeneat-logo.svg'

import { useStyles } from './styles'

const Splash: React.FC = () => {
  const classes = useStyles()
  return (
    <Container className={classes.container} maxWidth={false}>
      <Logo width="60%" height="60%" />
    </Container>
  )
}

export { Splash }
